<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>
              <div class="col-lg-9">
                <div>
                  <h4 class="comm-title">Won Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                  
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'VehicleDetails',
                                      params: {
                                        url_key: VehicleDetails.vehId,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  /></router-link>

                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                      :to="{
                                        name: 'VehicleDetails',
                                        params: {
                                          url_key: VehicleDetails.vehId,
                                        },
                                      }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>                                 
                                    <div class="rows">
                                      <div class="col-8"></div>
                                      <div class="col-12">
                                        <div class="text-center">
                                          <div>
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="showModal(VehicleDetails)"
                                            >
                                              Add to Arrived
                                            </button>
                                          </div>
                                      
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="overlay-bid-btn arrive-info"
                                      v-if="openShowModel && userinfo"
                                    >
                                      <button
                                        class="close-bid-btn bid-popup-close"
                                        @click="
                                          (openShowModel = false),
                                            (message = '')
                                        "
                                      >
                                        x
                                      </button>
                                      <h4>! Info</h4>
                                    
                                      <div class="row mar-b">
                                        <div class="col-6">
                                          <label for="kmclocked">
                                            Vehicle Name
                                          </label>
                                        </div>
                                        <div class="col-6">
                                          <span
                                            >{{ auctionEdit.brandName }}
                                            {{ auctionEdit.modelName }}
                                          </span>
                                        </div>

                                      
                                      </div>
                                      <div class="row mar-b">
                                        <div class="col-6">
                                          <label for="kmclocked">
                                            Current Km clocked
                                          </label>
                                        </div>
                                        <div class="col-6">
                                          <span
                                            >{{ auctionEdit.kmClocked }}
                                          </span>
                                        </div>
                                       
                                      </div>
                               

                                      <div class="row mar-b">
                                        <div class="col-6">
                                          <label for="kmclocked" class=""
                                            >Total Km Clocked</label
                                          >
                                        </div>
                                        <div class="col-6">
                                          <input
                                            style="height: 30px"
                                            type="number"
                                            v-model="updklclocked"
                                            class="form-control"
                                            placeholder="km"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        class="row"
                                        style="
                                          margin-top: 18px;
                                          margin-bottom: 10px;
                                        "
                                      >
                                        <div class="col-6">
                                          <div>
                                            <button
                                              class="bid-info-sub close-won"
                                              @click="openShowModel = false"
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div>
                                            <button
                                              class="bid-info-sub"
                                              @click="
                                                addToArrived(
                                                  auctionEdit,
                                                  'Deliverd'
                                                )
                                              "
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="form-group"
                                        v-if="message == 'Success'"
                                      >
                                        <div
                                          class="alert alert-warning cust-alert"
                                          role="alert"
                                        >
                                          {{ message }}
                                        </div>
                                      </div>
                                      <div
                                        class="form-group"
                                        v-else-if="message"
                                      >
                                        <div
                                          class="alert alert-warning cust-alert"
                                          role="alert"
                                        >
                                          {{ message }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Aucction Status Alert Model -->
                                    <div
                                      class="overlay-bid-btn warningStyle"
                                      v-if="openModel && userinfo"
                                    >
                                      <button
                                        class="close-bid-btn bid-popup-close btn_close"
                                        @click="
                                          (openModel = false), (message = '')
                                        "
                                      >
                                        x
                                      </button>
                                      <h4>! Warning</h4>
                                      <p>
                                        Are you sure you want to add
                                        <span
                                          >{{ auctionEdit.brandName }}
                                          {{ auctionEdit.modelName }}
                                          {{ auctionEdit.manYear }}</span
                                        >
                                        to {{ modelnameAuc }}?
                                        <span
                                          >!this action is irreversible</span
                                        >
                                      </p>
                                      <div class="row">
                                        <div class="col-6">
                                          <div>
                                            <button
                                              style="
                                                background-color: white;
                                                color: black;
                                              "
                                              class="bid-info-sub"
                                              @click="openModel = false"
                                            >
                                              No
                                            </button>
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div>
                                            <button
                                              style="
                                                background-color: white;
                                                color: black;
                                              "
                                              class="bid-info-sub"
                                              @click="
                                                changeAucStatus(
                                                  auctionEdit,
                                                  modelnameAuc
                                                )
                                              "
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="form-group"
                                        v-if="message == 'Success'"
                                      >
                                        <div
                                          class="alert alert-warning"
                                          role="alert"
                                        >
                                          {{ message }}
                                        </div>
                                      </div>
                                      <div
                                        class="form-group"
                                        v-else-if="message"
                                      >
                                        <div
                                          class="alert alert-warning"
                                          role="alert"
                                        >
                                          {{ message }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  components: {
    sideMenuVendor,
    PageNation,
    NotFound,
    navbar
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      auctionEdit: "",
      message: "",
      modelnameAuc: "",
      negotiationAmount: "",
      openNegotiationModel: false,
      customerNegAmount: null,
      kmmessage: "",
      aucStatusMessage: "",
      modeNeg: "",
      noOfBidders: 3,
      aucEndDate: "",
      openShowModel: false,
      updklclocked: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId != "undefined") {
        this.getpendingSellerAuctions(this.userinfo.sellerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.customerNegAmount = 200000;
  },
  methods: {
    showModal(e) {
      this.openShowModel = !this.openShowModel;
      this.auctionEdit = e;
    },
    changeAucStatus(aucInfo, aucStatus) {
      this.updateVehKmclocked(aucInfo);
      this.updateAuctionStatus(aucInfo, aucStatus);
    },
    updateVehKmclocked(aucInfo) {
      userService
        .updkmclocked(aucInfo, this.updklclocked)
        .then((response) => {
          this.message = response.data.Message;
          if (this.message == "Success") {
            this.message = "";

            var self = this;
            setTimeout(function () {
              self.closeopenShowModel();
            }, 2000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addToArrived(e) {
      this.message =""
      if (!this.updklclocked) {
        this.message = "Please update your vehicle's km clocked";
       
      } else if(this.updklclocked < e.kmClocked  ){

        this.message = "Total km clocked  cannot be lesser than current km clocked.";
      } 
      else if((this.updklclocked - e.kmClocked) > 2000 ){
        this.message = "Total km clocked cannot exceed 2000 km";
      }      
      else  {
        this.message = "";
        this.auctionEdit = e;
        this.openModel = !this.openModel;
        this.modelnameAuc = "Arrived";
      }
    },
    closeopenShowModel() {
      this.auctionStatusMessage = "";
      this.openShowModel = false;
      location.reaload();
    },
    reAuction(e) {
      this.auctionEdit = e;
      this.openModel = !this.openModel;
      this.openShowModel = false;
    },

    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    updateAuctionStatus(auctionDetails, aucStatus) {
      this.openModel = false; 
      //  console.log(auctionDetails,aucStatus);
      userService
        .updAuctionStatus(auctionDetails, aucStatus)
        .then((response) => {
          this.aucStatusMessage = response.data.Message;
          this.$toast.success("Saved suceesfully", {
            position: "top",
            duration: 3000,
           
          });

          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
          });
        });
    },
    getpendingSellerAuctions(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getSellerAuctionsStatus(id, "Sale Confirmed")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
           // console.log(this.AuctionsDetails);
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
            this.vechApproval = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.overlay-bid-btn {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(255 255 255);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #00000069;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.row.mar-b {
  margin-top: 8px;
}
</style>
